// @flow
import React from 'react';
import Layout from '../../components/layout';
import G from '../../components/glossary-link';
import Link from '../../components/link';
import type { FrontMatter } from '../../utils/types';
import { withFrontMatter } from '../../components';
import route from '../../routes';

class AcaCl1SkillsChecklist extends React.Component<{
  frontMatter: FrontMatter,
}> {
  render() {
    return (
      <Layout frontMatter={this.props.frontMatter} toc>
        <p>
          <Link to="https://www.canyoneering.net/wp-content/uploads/2020/05/CL1-Skills-Checklist-4-22-20.pdf">
            Original document
          </Link>{' '}
          from the{' '}
          <G displayTerm={true} id="american-canyoneering-association">
            ACA
          </G>
          's{' '}
          <Link to="https://www.canyoneering.net/resources/">
            resource page
          </Link>
          .
        </p>
        <p>
          OBJECTIVE: The development of intermediate level canyoneering /
          problem solving skills, basic canyon leadership techniques, group
          efficiencies, and competence in straightforward companion rescues.
        </p>
        <p>
          NOTE: Skills Checklists are cumulative in nature. Students must know
          all the skills from the '
          <Link to={route.aca_core_skills_checklist}>Core</Link>' and the '
          <Link to={route.aca_aspirant_skills_checklist}>Aspirant</Link>'
          Checklists in addition to the skills listed here.
        </p>

        <h2>Anchors</h2>
        <ul>
          <li>
            Construct, evaluate, rig, test, backup, and use{' '}
            <G id="deadman-anchor">dead man anchors</G>.
          </li>
          <li>
            Construct, evaluate, rig, test, backup, and use <G>cairn anchors</G>
            .
          </li>
          <li>
            Demonstrate how to backup / test a <G>marginal anchor</G>.
          </li>
          <li>
            Apply <G>sequencing</G> and friction to utilize otherwise marginal
            anchors.
          </li>
          <li>
            Set up and use <G id="meat-anchor">human anchors</G>. Explain why,
            when, and how to properly sequence this type of anchor.
          </li>
        </ul>

        <h2>Rigging</h2>
        <ul>
          <li>
            Set up and use <G>releasable twin rope system</G> (<G>jester</G>,{' '}
            <G>joker</G>), including three parts: (1) friction mechanism to
            allow controlled lowering, (2) tie-off that is releasable under
            tension, and (3) identifying risks and setting safety to mitigate
            those risks.
          </li>
          <li>
            Set up and use <G>Dynamic Courtesy Rigging</G> to facilitate hands
            free rappel start and trouble-free <G id="pull">rope retrieval</G>.
            Explain why, when, and proper usage and how to avoid{' '}
            <G>rope grooves</G> in soft rock.
          </li>
        </ul>

        <h2>Core Rope Work</h2>
        <ul>
          <li>
            Set up and use a <G>top-rope belay</G> system a.) from human anchor
            (hip belay) 10ft or less, b.) from fixed <G>anchor</G>.
          </li>
          <li>
            Demonstrate use of simple <G id="lift-and-shift">lift-shift</G>{' '}
            systems to help someone on rappel free stuck gear.
          </li>
          <li>
            Tension and securely tie off a <G id="guide-line">guide rope</G> at
            the bottom using <G>mechanical advantage</G>.
          </li>
          <li>
            Set up and use a retrievable <G>guided rappel</G> system tensioning
            from the bottom.
          </li>
          <li>
            Protect <G>traverse</G> and rappel with a retrievable{' '}
            <G>safety line</G>.
          </li>
          <li>
            Set up and use a <G>counter-weight rappel</G>, anchoring from
            bottom.
          </li>
        </ul>

        <h2>On Rope Techniques, Teamwork, Companion Rescue</h2>
        <ul>
          <li>
            Use <G>drop loop</G> 2:1 to provide lift for rappeller to free stuck
            gear.
          </li>
          <li>
            Convert static <G id="single-rope-technique">single rope rigging</G>{' '}
            (e.g. <G id="static-block">block</G>) to lower. Use{' '}
            <G>hands free backup</G> (i.e. <G>friction hitch</G>) when lowering.
          </li>
          <li>
            Demonstrate <G>tandem rappel</G> (aka assisted rappel).
          </li>
          <li>
            Demonstrate the following advanced partner capture techniques: a)
            Foot Capture; b) <G>Partner Capture</G>; c){' '}
            <G id="human-pyramid">Human Ladder</G>.
          </li>
          <li>Perform a Dynamic Belay (Lowering while maintaining belay).</li>
          <li>
            Perform controlled rappel <G>free hanging</G> and/or up to 250'+
            adding friction mid-rappel; communication - radios; hanging packs.
          </li>
        </ul>

        <h2>Participant Coaching / Teaching</h2>
        <ul>
          <li>Coach / Teach a person to basic rappel.</li>
          <li>
            Coach / Teach a person to basic <G id="bridging">bridge</G>;{' '}
            <G id="stemming">stem</G>; <G>chimney</G>, and{' '}
            <G id="down-climb">downclimb</G>.
          </li>
          <li>
            Belay a climber; climbing up, a.) using rope from human anchor (hip
            belay) 10ft or less b.) using rope from fixed anchor.
          </li>
        </ul>

        <h2>Emergency Response</h2>
        <ul>
          <li>
            Activate EMS system Non-Life threatening (Local{' '}
            <G id="search-and-rescue">SAR</G>; Emergency Contacts).
          </li>
          <li>
            Activate EMS system Life Threatening or Time Critical (Local SAR;
            Emergency Contacts).
          </li>
          <li>
            Contact Rescue - Rappel on secured rope to provide assistance to
            person stuck on rappel; provide <G>foot loop</G> to free stuck gear
            (Not a <G>pick-off</G>).
          </li>
        </ul>

        <h2>Canyon Leadership</h2>
        <ul>
          <li>
            Weather – interpret Radar, Water Vapor, & Infrared Satellite Imagery
          </li>
          <li>
            Perform Pack Checks (for selected canyon) – check for appropriate:
            Water; Food; Clothing; Footwear; Equipment; First Aid Supplies;
            Technical eBag (extra <G>hooks</G>, <G>webbing</G>, <G>rapides</G>,{' '}
            <G>Potshot</G>, etc.).
          </li>
          <li>Model / Teach: basic bridging; stemming; chimneying.</li>
          <li>Manage participant fearful of rappelling (from bottom).</li>
          <li>
            Set Safe zones / gates – move group from safe zone to safe zone
            (keep group together).
          </li>
          <li>Recognize and treat symptoms of Hypothermia.</li>
          <li>Recognize and treat symptoms of Hyperthermia.</li>
          <li>
            Wilderness First Aid - perform: a) Splint a lower leg injury; b)
            Stabilize & Horizontal Evacuation.
          </li>
        </ul>

        <h2>Problem Solving Scenarios</h2>
        <ul>
          <li>
            Scenario 1: Shirt / Hair stuck in{' '}
            <G id="rappel-device">rappelling device</G>.
          </li>
          <li>
            Scenario 2:{' '}
            <G displayTerm={true} id="rope-management">
              Tangled roped
            </G>{' '}
            and/or rope doesn't touch the ground.
          </li>
          <li>Scenario 3: Late start - move group expeditiously.</li>
          <li>
            Scenario 4: Horizontal Evacuation of lower leg injury - stable
            patient; normal conditions.
          </li>
        </ul>

        <h2>Specialized Skills for Class A/B Canyons</h2>
        <p>
          OBJECTIVE: Provide students with the advanced personal and group
          canyoneering skills needed to become effective problem-solvers in
          Class{' '}
          <G displayTerm={true} id="a-water-rating">
            A
          </G>
          /
          <G displayTerm={true} id="b-water-rating">
            B
          </G>{' '}
          canyons.
        </p>
        <h3>Class A/B Canyons — Pothole Escape</h3>
        <ul>
          <li>
            Escape a <G>pothole</G> using <G>partner assist</G> techniques.
          </li>
          <li>
            Escape a pothole using counter-weight techniques (
            <G id="sand-bag">pot shot</G>; <G>pack toss</G>). Toss single Pot
            Shot as simple pothole counter weight, simple ascend out.
          </li>
          <li>Escape a pothole using aid hooking techniques.</li>
        </ul>

        <h2>Specialized Skills for Class C Canyons</h2>
        <p>
          OBJECTIVE: Provide students with the advanced personal and group
          canyoneering skills needed to become effective problem-solvers in{' '}
          <G displayTerm={true} id="c-water-rating">
            Class C
          </G>{' '}
          canyons.
        </p>
        <h3>Class C Canyons — Core Skills</h3>
        <ul>
          <li>
            Assist swimmer(s) while maintaining separation with sequencing, tag
            lines, throw bags, and/or flotation devices.
          </li>
          <li>
            Jumping into water (Max distance 10ft./10ft. Min depth) identify
            hazards/depth, correct body position ("Pencil" technique).
          </li>
          <li>Cut self off of entangled rope in water (10 sec max).</li>
          <li>Swim for 4 minutes without floatation.</li>

          <h3>Recommendations After Training</h3>
          <ul>
            <li>
              Practice technical skills in low-risk conditions, such as on clean
              and low angle "slab" type environments or on vertical terrain with
              an effective Top or{' '}
              <G id="fireman-belay">Bottom (Fireman’s) Belay</G>.
            </li>
            <li>
              Students should descend intermediate to advanced canyons with
              competent mentors, taking on progressively more anchor, rigging
              and problem-solving responsibilities.
            </li>
          </ul>
        </ul>
      </Layout>
    );
  }
}

export default withFrontMatter('cl1-skills-checklist')(AcaCl1SkillsChecklist);
